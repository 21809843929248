<template>
  <div class="container">
    <!-- 头部 -->
    <Header :tab="7"/>
    <!-- 身体 -->
    <div class="main wow animate__slideInUp" data-wow-iteration="1" :data-wow-offset="height_top">
      <div class="banner wow animate__slideInUp" data-wow-iteration="1" :data-wow-offset="height_top">
        <img v-if="obj" src="../../assets/imgs/n_banner01.png" class="banner_img" alt />
        <div class="banner_box">
          <div class="banner_box_l wow animate__slideInUp" data-wow-delay="0" data-wow-iteration="1"
            :data-wow-offset="height_top">
            <div class="banner_box_l_title">我们的客户遍布全国</div>
            <div class="banner_box_l_center">专注高品质网站建设与定制开发服务！</div>
            <div v-if="obj">
              <div class="banner_box_l_txt" v-html="obj.info1"></div>
            </div>
          </div>

          <div class="banner_box_r wow animate__slideInUp" data-wow-delay="0.2s" data-wow-iteration="1"
            :data-wow-offset="height_top">
            <div class="banner_box_r_box">
              <div class="banner_box_r_item">
                <div class="banner_box_r_item_t">开发经验</div>
                <div class="banner_box_r_item_c">
                  <span>
                    <CountTo :startVal="0" :endVal="10" :duration="3000"></CountTo>
                  </span>+
                </div>
                <div class="banner_box_r_item_n">Years</div>
              </div>
              <div class="banner_box_r_item">
                <div class="banner_box_r_item_t">团队人数</div>
                <div class="banner_box_r_item_c">
                  <span>
                    <CountTo :startVal="0" :endVal="30" :duration="3000"></CountTo>
                  </span>+
                </div>
                <div class="banner_box_r_item_n">Team size</div>
              </div>
              <div class="banner_box_r_item">
                <div class="banner_box_r_item_t">服务客户</div>
                <div class="banner_box_r_item_c">
                  <span>
                    <CountTo :startVal="0" :endVal="1000" :duration="3000"></CountTo>
                  </span>+
                </div>
                <div class="banner_box_r_item_n">Cases</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- 主体 -->
      <div class="main_box">
        <!-- 头部 -->
        <div class="main_box_top wow animate__slideInUp" data-wow-delay="0" data-wow-iteration="1"
          :data-wow-offset="height_top">
          <div class="main_box_top_l wow animate__slideInUp" data-wow-delay="0" data-wow-iteration="1"
            :data-wow-offset="height_top">
            <div class="main_box_top_l_txt">重视每次合作 我们与您共同进步</div>
            <div class="main_box_top_l_title">有经验 有态度</div>
          </div>
          <div ref="banner" class="main_box_top_r wow animate__slideInUp" data-wow-delay="0.2s" data-wow-iteration="1"
            :data-wow-offset="height_top">
            <div class="main_box_top_r_title">我们是晓艾，持续为客户提供技术支持与服务！</div>
            <div class="main_box_top_r_title_txt">
              We are Xiaoai, Continue to Provide Technical Support and
              Services to Customers!
            </div>
            <div class="main_box_top_r_img_box">
              <img src="../../assets/imgs/women.png" class="main_box_top_r_img" alt />
            </div>
            <div v-if="obj">
              <div class="main_box_top_r_txt" v-html="obj.info2"></div>
            </div>
          </div>
        </div>
        <div class="main_box_top wow animate__slideInUp" data-wow-delay="0" data-wow-iteration="1"
          :data-wow-offset="height_top">
          <div class="main_box_top_l wow animate__slideInUp" data-wow-delay="0" data-wow-iteration="1"
            :data-wow-offset="height_top">
            <div class="main_box_top_l_txt">我们做的事很纯粹</div>
            <div class="main_box_top_l_title">为客户创造价值</div>
          </div>
          <div ref="banner" class="main_box_top_r wow animate__slideInUp" data-wow-delay="0.2s" data-wow-iteration="1"
            :data-wow-offset="height_top">
            <div class="main_box_top_r_title">用案例作品，证明我们存在的价值！</div>
            <div class="main_box_top_r_title_txt">We are Designers, Engineers and Dreamers!</div>
            <div v-if="obj">
              <div class="main_box_top_r_txts" v-html="obj.info3"></div>
            </div>
          </div>
        </div>
      </div>
      <!-- 地图 -->
      <div v-if="obj" class="iframe wow animate__slideInUp" data-wow-delay="0" data-wow-iteration="1"
        :data-wow-offset="height_top">
        <!-- <iframe id="iframe" v-if="isShow" :src="obj.info_address" frameborder="0"></iframe> -->
        <div>
          <img class="iframe_img" :src="obj.info_address" alt />
        </div>
      </div>
    </div>
    <!-- 底部 -->
    <Footer class="wow animate__slideInUp" data-wow-iteration="1" :data-wow-offset="height_top" />
  </div>
</template>
  
<script>
import Footer from "@/components/Footer/Footer";
import Header from "@/components/Header/Header";
import storage from "@/utils/storage";
import CountTo from "vue-count-to"; //引入数字滚动器
import { articlelinfo1 } from "@/api/axios/axios.api";
import { WOW } from "wowjs";

export default {
  components: {
    Header,
    Footer,
    CountTo
  },
  data() {
    return {
      height_top: storage.height_top,
      tabIndex: "",
      isActive: 1,
      isShow: false,
      obj: null
    };
  },
  methods: {
    // 上一页
    prevs() {
      this.$refs.carousel.prev();
    },
    // 下一页
    nexts() {
      this.$refs.carousel.next();
    },
    // 获取数据
    async getData() {
      let res = await articlelinfo1({ all: 1 });
      this.obj = res.data;
      // this.isShow = true;
      // if (res.status == 200) {
      //   let wow = new WOW({
      //     live: true
      //   });
      //   wow.init();
      // }
    }
  },
  created() { },
  mounted() {
    this.getData();
    this.$nextTick(() => {
      let wow = new WOW({
        live: true
      });
      wow.init();
    });
  },
  watch: {}
};
</script>
  
<style lang="less" scoped>
@media screen and (min-width : 1400px) {
  .banner {
    color: #fff;
    height: 871px;

    .banner_img {
      position: absolute;
      height: 871px;
      width: 100%;
    }

    .banner_box {
      height: 100%;
      position: relative;
      padding: 0 10%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;

      .banner_box_l {
        width: 46%;
        font-size: 14px;
        margin-right: 8%;

        .banner_box_l_title {
          font-size: 60px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
        }

        .banner_box_l_center {
          font-size: 28px;
          margin: 96px 0 52px 0;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
        }

        .banner_box_l_txt {
          line-height: 30px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 13;
        }
      }

      .banner_box_r {
        width: 46%;
        display: flex;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
        text-align-last: left;


        .banner_box_r_box {
          display: flex;
          align-items: center;
          justify-content: center;

          .banner_box_r_item {
            flex: 1;
            display: flex;
            flex-direction: column;
            align-items: center;
            border-right: 1px solid #f5f5f5;
            min-width: 143px;
            padding: 0 20px;

            .banner_box_r_item_t {
              font-size: 18px;
              margin-bottom: 65px;
              width: 100%;
              text-align: left;
              padding-left: 40px;
            }

            .banner_box_r_item_c {
              width: 100%;
              font-size: 20px;
              display: flex;
              align-items: center;
              margin-bottom: 25px;
              position: relative;
              margin-right: -30px;

              span {
                font-size: 80px;
                // margin-right: 35px;
                margin-right: 10px;
              }
            }

            .banner_box_r_item_n {
              font-size: 18px;
              width: 100%;
              text-align: left;
              padding-left: 60px;
            }
          }

          .banner_box_r_item:nth-child(3) .banner_box_r_item_n {
            font-size: 18px;
            width: 100%;
            text-align: center;
            padding-left: 100px;
            box-sizing: border-box;
          }

          .banner_box_r_item:last-child {
            border: 0;

          }
        }
      }
    }
  }

  // 身体
  .main_box {
    padding: 0 10%;
    // padding-bottom: 42px;
    background-color: #fff;

    // 头部
    .main_box_top {
      padding: 38px 0;
      box-sizing: border-box;
      display: flex;
      border-bottom: 1px solid #f5f5f5;

      .main_box_top_l {
        width: 30%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-right: 30px;

        .main_box_top_l_txt {
          font-size: 20px;
          color: #333333;
          margin-bottom: 26px;
        }

        .main_box_top_l_title {
          font-size: 40px;
          color: #333333;
          font-weight: bold;
        }
      }

      .main_box_top_r {
        width: 70%;
        line-height: 34px;

        .main_box_top_r_title {
          font-size: 20px;
          color: #333333;
        }

        .main_box_top_r_title_txt {
          color: #999999;
          font-size: 18px;
        }

        .main_box_top_r_img_box {
          .main_box_top_r_img {
            width: 100%;
          }
        }

        .main_box_top_r_txt {
          color: #333333;
          font-size: 14px;
        }

        .main_box_top_r_txts {
          color: #333333;
          font-size: 14px;
          margin-top: 10px;
        }
      }
    }

    .main_box_top:last-child {
      border: 0;
    }
  }

  // 地图
  .iframe {
    min-height: 450px;

    .iframe_img {
      width: 100%;
    }
  }
}

@media screen and (max-width : 1399px) {
  .banner {
    color: #fff;
    height: 871px;
    min-width: 1300px;

    .banner_img {
      position: absolute;
      height: 871px;
      width: 100%;
    }

    .banner_box {
      height: 100%;
      position: relative;
      padding: 0 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;

      .banner_box_l {
        width: 40%;
        font-size: 14px;
        // margin-right: 8%;

        .banner_box_l_title {
          font-size: 60px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
        }

        .banner_box_l_center {
          font-size: 28px;
          margin: 96px 0 52px 0;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
        }

        .banner_box_l_txt {
          line-height: 30px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 13;
        }
      }

      .banner_box_r {
        width: 46%;
        display: flex;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
        text-align-last: left;


        .banner_box_r_box {
          display: flex;
          align-items: center;
          justify-content: center;

          .banner_box_r_item {
            flex: 1;
            display: flex;
            flex-direction: column;
            align-items: center;
            border-right: 1px solid #f5f5f5;
            min-width: 143px;
            padding: 0 20px;

            .banner_box_r_item_t {
              font-size: 18px;
              margin-bottom: 65px;
              width: 100%;
              text-align: left;
              padding-left: 40px;
            }

            .banner_box_r_item_c {
              width: 100%;
              font-size: 20px;
              display: flex;
              align-items: center;
              margin-bottom: 25px;
              position: relative;
              margin-right: -30px;

              span {
                font-size: 80px;
                // margin-right: 35px;
                margin-right: 10px;
              }
            }

            .banner_box_r_item_n {
              font-size: 18px;
              width: 100%;
              text-align: left;
              padding-left: 60px;
            }
          }

          .banner_box_r_item:nth-child(3) .banner_box_r_item_n {
            font-size: 18px;
            width: 100%;
            text-align: center;
            padding-left: 100px;
            box-sizing: border-box;
          }

          .banner_box_r_item:last-child {
            border: 0;

          }
        }
      }
    }
  }

  // 身体
  .main_box {
    padding: 0 20px;
    // padding-bottom: 42px;
    background-color: #fff;
    min-width: 1300px;


    // 头部
    .main_box_top {
      padding: 38px 0;
      box-sizing: border-box;
      display: flex;
      border-bottom: 1px solid #f5f5f5;

      .main_box_top_l {
        width: 30%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-right: 30px;

        .main_box_top_l_txt {
          font-size: 20px;
          color: #333333;
          margin-bottom: 26px;
        }

        .main_box_top_l_title {
          font-size: 40px;
          color: #333333;
          font-weight: bold;
        }
      }

      .main_box_top_r {
        width: 70%;
        line-height: 34px;

        .main_box_top_r_title {
          font-size: 20px;
          color: #333333;
        }

        .main_box_top_r_title_txt {
          color: #999999;
          font-size: 18px;
        }

        .main_box_top_r_img_box {
          .main_box_top_r_img {
            width: 100%;
          }
        }

        .main_box_top_r_txt {
          color: #333333;
          font-size: 14px;
        }

        .main_box_top_r_txts {
          color: #333333;
          font-size: 14px;
          margin-top: 10px;
        }
      }
    }

    .main_box_top:last-child {
      border: 0;
    }
  }

  // 地图
  .iframe {
    // min-height: 450px;
    min-width: 1300px;

    .iframe_img {
      width: 100%;
    }
  }
}
</style>
  